<!--
 * @Description: 我的收藏
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 09:29:49
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-21 22:23:14
-->
<template>
  <div class="">
    <div class="w ww shoucang">
      <div class="shoucang_top">
        <div class="pc_fr shoucang_top_rt">
          <input
            name=""
            v-model="productName"
            type="text"
            :placeholder="this.$t('search')"
          />
          <button @click="search(1)"></button>
        </div>
        <ul class="zx_t">
          <li class="active">
          {{this.$t('AllGoods')}}<span>（{{ totalCount }}）</span><i></i>
          </li>
        </ul>
        <div class="clear"></div>
      </div>

      <ul class="zx_c">
        <li class="thisclass">
          <div class="shoucang_con">
            <ul v-if="dataList.length > 0">
              <li
                v-for="(v, i) in dataList"
                @click="goDetail(v.productRefOwid)"
                :key="i"
              >
                <a>
                  <div class="shoucang_img"><img :src="v.productPic" /></div>
                  <h3 class="ellipsis2">
                    {{ v.productName }}<span>({{ v.productCode }})</span>
                  </h3>
                  <p>￥{{ v.specsPrice }}<span></span></p>
                </a>
              </li>
              <div class="clear"></div>
            </ul>
            <div v-if="dataList.length == 0">
              <null-tip></null-tip>
            </div>
          </div>
        </li>
      </ul>

      <Pagination
        :totalPage="totalPage"
        :currentPage="currentPage"
        @pageFun="goPageSrh"
      />
    </div>
  </div>
</template>

<script>
import { Collect } from "@/api/index";
import { dealUrlHttp } from "@/utils/com";
import Pagination from "@/components/component/Pagination.vue";
import NullTip from "@/components/component/NullTip.vue";
export default {
  name: "Collect",
  data() {
    return {
      productName: "",
      dataList: [],
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
    };
  },
  async created() {
    this.search();
  },
  methods: {
    //分页搜索
    goPageSrh(pageNo) {
      this.search(pageNo);
    },

    async search(pageNo = 1) {
      let res = await Collect.clctList({
        pageNo,
        pageSize: 15,
        productName: this.productName,
      });
      let result = res.records || [];
      result.forEach((e) => {
        e.productPic = dealUrlHttp(e.productPic);
      });
      this.totalPage = res.totalPage;
      this.currentPage = res.currentPage;
      this.totalCount = res.totalCount;
      this.dataList = result;
    },

    //详情
    goDetail(owid) {
      this.$router.push({ path: "/productdetails", query: { proId: owid } });
    },
  },
  components: {
    Pagination,
    NullTip,
  },
};
</script>
