<!--
 * @Description: 空提示
 * @version: 1.0
 * @Author: ziwei
 * @Date: 2021-08-17 12:04:00
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 16:52:59
-->
<template>
  <div class="no-data" :style="stl">
    <img class="tip-img" src="@/assets/img/empty.png" />
    <h6 class="tip-txt">{{ tip }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: String,
      default: "NO DATA",
    },
    stl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less">
.no-data {
  text-align: center;
  padding: 20%;
}
.tip-img {
  max-width: 150px;
  width: 40%;
  min-width: 100px;
}
.tip-txt {
  color: #999;
  font-weight: normal;
  font-size: 0.05rem;
}
</style>
